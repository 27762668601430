import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/layout.css";
import "../css/login.css";
import findGif from "../css/loading_old.gif";
import Spinner from "react-bootstrap/Spinner";

export default function Login() {
  const history = useHistory();
  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // +++++++++++++++++++++++++++++

  const { referralcode } = useParams();

  const [Phone, setPhone] = useState();
  const [twofactor_code, settwofactor_code] = useState();
  const [otp, setOtp] = useState(false);
  const [isLoading1, setIsloading1] = useState(false);
  const [secretCode, setSecretCode] = useState();

  let refs = useLocation().pathname.split("/")[2];
  const [referral, setReferral] = useState(refs);

  const [receivedOTP, setReceivedOTP] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if ("OTPCredential" in window) {
        const ac = new AbortController();

        try {
          const otpCredential = await navigator.credenstials?.get({
            otp: { transport: ["sms"] },
            signal: ac.signal,
          });
          

          setReceivedOTP(otpCredential?.code);
          ac.abort();
        } catch (err) {
          ac.abort();
          console.log(err);
        }
      }
    };

    fetchData();
  }, []);

  const handleClick = async (e) => {

    e.preventDefault();
    if (!Phone) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone number",
      });
    } else if (Phone.length != 10) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please  enter currect phone number",
      });
    } else {
      setIsloading1(true);
      await axios
        .post(baseUrl + `login`, {
          Phone,
          referral,
        })
        .then((respone) => {
          setIsloading1(false);
          if (respone.data.status == 101) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: respone.data.msg,
            });
          } else if (respone.data.status == 200) {
            setOtp(true);
            setSecretCode(respone.data.secret);
            if (respone.data.myToken) {
              Swal.fire({
                icon: "success",
                title: "OTP",
                text: "Demo Account Testing OTP: " + respone.data.myToken,
              });
            }
          }
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        });
    }
  };

  const varifyOtp = async (e) => {
    e.preventDefault();

    if (!Phone) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone number",
      });
      return;
    }

    setIsloading1(true);

    try {
      const response = await axios.post(baseUrl + "login/finish", {
        Phone,
        twofactor_code,
        referral,
        secretCode,
      });

      setIsloading1(false);

      if (response.data.status === 101) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.msg,
        });
      } else if (response.data.status === 200) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        // Reload the page and redirect after a delay
        setTimeout(() => {
          window.location.reload(true);
          history.push("/Games"); // Assuming you have 'history' available
        }, 1000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      setIsloading1(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  // otp valid
  const [isValid, setIsValid] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    let timerInterval;

    if (isValid && seconds > 0) {
      timerInterval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(timerInterval);
      setShowResend(true); // Show the Resend button when OTP expires
      setIsValid(false);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isValid, seconds]);

  const handleResendClick = (e) => {
    handleClick(e);
    setIsValid(true);
    setSeconds(60);
    setShowResend(false); // Hide the Resend button after clicking it
  };
  return (
    <>
      <div className="leftContainer" style={{ minHeight: "100vh" }}>
        <div className="login_main-area ">
         
          <div className="login_box_main_box  center-xy mx-auto">
          <div className='snip-img'>
                  <img 
                    src='/skill-logo.jpg'
                    className='loginimage'
                    alt=''
                  />
                </div>
            <div className="form_haeding" style={{paddingTop:"10px"}}>Sign in</div>

            <div className="login_input_field_main">
              <div className="login_input_field_inner">
                <span>+91</span>
                <input
                  className="form_control"
                  name="mobile"
                  type="tel"
                  placeholder="Mobile number"
                  onChange={(e) => {
                    setPhone(e.target.value);
                    if (e.target.value.length > 10) {
                    }
                  }}
                />
              </div>
            </div>

            {otp && (
              <>
                <div className="login_input_field_main otp_filed">
                  <div className="login_input_field_inner">
                    <span>OTP</span>
                    <input
                      className="form_control input_filed"
                      name="password"
                      type="tel"
                      placeholder="Enter OTP"
                       autoComplete="one-time-code"
                      onChange={(e) => settwofactor_code(e.target.value)}
                    />
                  </div>
                </div>
                <div className="resend_otp_div">
                  {isValid ? (
                    <p className="otp_expire">
                      OTP is valid for {seconds} seconds.
                    </p>
                  ) : seconds === 0 ? (
                    <div className="otp_expire">
                      {showResend && (
                        <button onClick={handleResendClick}>Resend OTP</button>
                      )}
                    </div>
                  ) : (
                    <p className="otp_expire">
                      OTP is valid for {seconds} seconds.
                    </p>
                  )}
                </div>
              </>
            )}

            {!otp &&
              (isLoading1 ? (
                <button className="Login-button cxy" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </button>
              ) : (
                <button
                  className="Login-button cxy "
                  onClick={(e) => {
                    handleClick(e);
                    setIsValid(true);
                  }}
                >
                  Get OTP
                </button>
              ))}

            {otp &&
              (isLoading1 ? (
                <button className="Login-button cxy" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </button>
              ) : (
                <button
                  className="Login-button cxy"
                  onClick={(e) => varifyOtp(e)}
                >
                  Verify
                </button>
              ))}
          </div>
          <div className="login-footer mb-5">
            By continuing I agree that skillludo may
            store and process my data in accordance with the{" "}
            <Link to="/about">About</Link> <Link to="/contact-us">Contact</Link>{" "}
            <Link to="/Terms">Privacy Policy</Link> and that I am 18 years or
            older. I am not playing from Assam, Odisha, Nagaland, Sikkim,
            Meghalaya, Andhra Pradesh, or Telangana.
          </div>
        </div>
      </div>
    </>
  );
}
